import {
  AppstoreOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Badge, Button, Col, Input, Row, Table, Tag, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Card } from "../Cliente/ClientesDashboard/ClientesDashboard";
import { ThemeContext } from "../../../contexts/ThemeContext";
import "./maquinas.css";

function isAtLeastFiveMinutesAgo(dateString) {
  const date = new Date(dateString);

  const adjustedDate = new Date(date.getTime() - 3 * 60 * 60 * 1000);

  const now = new Date();

  const differenceInMs = now - adjustedDate;

  return differenceInMs <= 5 * 60 * 1000;
}

export default function Maquinas() {
  const [maquinas, setMaquinas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modoExibicao, setModoExibicao] = useState("tabela");
  const [rows, setRows] = useState([]);
  const [maquinasFiltradas, setMaquinasFiltradas] = useState([]);
  const [filterText, setFilterText] = useState("");

  const { themeIsDark, getColorByTheme } = useContext(ThemeContext);

  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const isMobile = window.innerWidth < 800;

  useEffect(() => {
    const filteredeMaquinas = maquinas.filter(({ nome }) =>
      nome.toLowerCase().includes(filterText.toLowerCase())
    );

    if (filterText === "") {
      const maquinasAdaptadas = maquinas?.map(
        ({ nome, store_id, descricao, status, maquininha_serial, ...rest }) => {
          const pegarTextoStatus = () => {
            switch (status) {
              case "OFFLINE":
                return "Inativa";
              case "ONLINE":
                return "Ativa";
              case "PAGAMENTO_RECENTE":
                return "Ativa +";
              default:
                return status;
            }
          };

          return {
            nomeMaquina: nome,
            mercadoPagoId: store_id,
            idPseg: maquininha_serial ?? "-",
            loja: descricao,
            status: pegarTextoStatus(),
            atividadeOntem: "-",
            ...rest,
          };
        }
      );

      setRows(maquinasAdaptadas);
      return;
    }

    const maquinasAdaptadas = filteredeMaquinas?.map(
      ({ nome, store_id, descricao, status, maquininha_serial, ...rest }) => {
        const pegarTextoStatus = () => {
          switch (status) {
            case "OFFLINE":
              return "Inativa";
            case "ONLINE":
              return "Ativa";
            case "PAGAMENTO_RECENTE":
              return "Ativa +";
            default:
              return status;
          }
        };

        return {
          nomeMaquina: nome,
          mercadoPagoId: store_id,
          idPseg: maquininha_serial ?? "-",
          loja: descricao,
          status: pegarTextoStatus(),
          atividadeOntem: "-",
          ...rest,
        };
      }
    );

    setMaquinasFiltradas(maquinasAdaptadas);
  }, [filterText, maquinas]);

  const dataData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setMaquinas(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    const maquinasAdaptadas = maquinas?.map(
      ({ nome, store_id, descricao, status, maquininha_serial, ...rest }) => {
        const pegarTextoStatus = () => {
          switch (status) {
            case "OFFLINE":
              return "Inativa";
            case "ONLINE":
              return "Ativa";
            case "PAGAMENTO_RECENTE":
              return "Ativa +";
            default:
              return status;
          }
        };

        return {
          nomeMaquina: nome,
          mercadoPagoId: store_id,
          idPseg: maquininha_serial ?? "-",
          loja: descricao,
          status: pegarTextoStatus(),
          atividadeOntem: "-",
          ...rest,
        };
      }
    );

    setRows(maquinasAdaptadas);
  }, [maquinas]);

  const handleMaquinaClick = (maquina) => {
    const {
      id,
      nomeMaquina,
      mercadoPagoId,
      pulso,
      estoque,
      loja,
      maquininha_serial,
      status,
    } = maquina;
    const maquinaInfos = {
      nome: nomeMaquina,
      storeId: mercadoPagoId,
      pulso,
      estoque,
      descricao: loja,
      maquininha_serial,
      status,
    };
    navigate(`/pagamentos/${id}`, {
      state: maquinaInfos,
    });
  };

  const columns = [
    {
      title: "Nome da máquina",
      dataIndex: "nomeMaquina",
      key: "nomeMaquina",
    },
    {
      title: "ID MP",
      dataIndex: "mercadoPagoId",
      key: "mercadoPagoId",
    },
    {
      title: "ID PSEG",
      dataIndex: "idPseg",
      key: "idPseg",
    },
    {
      title: "Loja",
      dataIndex: "loja",
      key: "loja",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, row) => {
        const textValue = row.status;

        return (
          <Tag
            color={
              textValue === "Inativa"
                ? "#FEF0F4"
                : textValue === "Ativa"
                ? "#EEFBF4"
                : "#F0FAFF"
            }
            style={{
              borderRadius: "8px",
              color:
                textValue === "Inativa"
                  ? "#D50B3E"
                  : textValue === "Ativa"
                  ? "#17663A"
                  : "#0075AD",
            }}
          >
            {textValue}
          </Tag>
        );
      },
    },
    {
      title: "Atividade da máquina",
      dataIndex: "atividadeOntem",
      key: "atividadeOntem",
      render: (value, row) => {
        const online = new Date(row.ultimaVezOnline);

        const diff = new Date() - online;

        const horas = Math.floor(diff / (1000 * 60 * 60));
        const minutos = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        const intervalo = `${String(horas).padStart(2, "0")}:${String(
          minutos
        ).padStart(2, "0")}`;

        return <span>{row.status === "Inativa" ? "00:00" : intervalo}</span>;
      },
    },
  ];

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        paddingRight: "15px",
        backgroundColor: themeIsDark && "#202029",
      }}
    >
      <Row
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {" "}
          <Typography
            style={{
              fontSize: "28px",
              fontWeight: "700",
              color: getColorByTheme(themeIsDark),
            }}
          >
            Máquinas
          </Typography>
          <div
            onClick={() =>
              setModoExibicao((prev) =>
                prev === "blocos" ? "tabela" : "blocos"
              )
            }
            style={{
              cursor: "pointer",
              gap: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {modoExibicao === "blocos"
              ? "Exibir em tabela"
              : "Exibir em blocos"}
            {modoExibicao === "blocos" ? (
              <TableOutlined />
            ) : (
              <AppstoreOutlined />
            )}
          </div>
        </div>
        <Input
          placeholder="Buscar"
          style={{
            borderRadius: "8px",
            maxWidth: "300px",
            backgroundColor: themeIsDark && "transparent",
            color: themeIsDark && "white",
          }}
          value={filterText}
          onChange={({ target }) => setFilterText(target.value)}
        />
        {/*<Button
          style={{
            backgroundColor: "#00ACFF",
            borderRadius: "8px",
            color: "white",
            padding: "20px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nova máquina
          <PlusOutlined />
        </Button> */}
      </Row>
      <Row style={{ width: "100%", marginTop: "20px" }}>
        {" "}
        {modoExibicao === "tabela" && (
          <Table
            className={themeIsDark && "dark-table"}
            columns={columns}
            dataSource={filterText.length > 0 ? maquinasFiltradas : rows}
            locale={{
              emptyText: (
                <div>Não foram encontrados resultados para sua pesquisa.</div>
              ),
            }}
            style={{ width: "100%", overflowX: "auto" }}
            onRow={(record) => {
              return {
                onClick: () => handleMaquinaClick(record),
              };
            }}
          />
        )}
      </Row>
      {modoExibicao === "blocos" && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
            gridGap: "20px",
          }}
        >
          {maquinas.map((maquina) => {
            const { nome, store_id, descricao, status, maquininha_serial } =
              maquina;
            return (
              <Card>
                <Row
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() =>
                    handleMaquinaClick({
                      nomeMaquina: nome,
                      mercadoPagoId: store_id,
                      loja: descricao,
                      ...maquina,
                    })
                  }
                >
                  <Col style={{ flex: 1 }}>
                    <Typography
                      style={{
                        fontSize: "18px",
                        color: getColorByTheme(themeIsDark),
                      }}
                    >
                      {nome}
                    </Typography>
                    <Typography style={{ color: "#55556D" }}>
                      ID MP {store_id}
                    </Typography>
                    <Typography style={{ color: "#55556D" }}>
                      ID PSE {maquininha_serial}
                    </Typography>
                    <Typography style={{ color: "#55556D" }}>
                      Loja {descricao}
                    </Typography>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      alignItems: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Tag
                      color={status === "OFFLINE" ? "#FEF0F4" : "#EEFBF4"}
                      style={{
                        borderRadius: "8px",
                        color: status === "OFFLINE" ? "#D50B3E" : "#17663A",
                      }}
                    >
                      {status === "OFFLINE" ? "Inativa" : "Ativa"}
                    </Tag>
                    <div>
                      <Typography style={{ color: "#55556D" }}>
                        Ativ. ontem
                      </Typography>
                      <Typography
                        style={{ color: "#55556D", textAlign: "center" }}
                      >
                        -
                      </Typography>
                    </div>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
}
