import { createContext, useState } from "react";

export const ThemeContext = createContext({
  themeIsDark: false,
});

export const ThemeContextProvider = ({ children }) => {
  const [themeIsDark, setThemeIsDark] = useState(false);

  const getColorByTheme = (themeIsDark) => {
    if (themeIsDark) {
      return "white";
    }

    return "black";
  };

  return (
    <ThemeContext.Provider
      value={{ themeIsDark, setThemeIsDark, getColorByTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
