import { Button, Input } from "antd";
import axios from "axios";
import { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword() {
  const [password, setPassword] = useState("");

  const { token } = useParams();

  const navigate = useNavigate();

  const changePassword = async () => {
    await axios
      .post(`${process.env.REACT_APP_SERVIDOR}/reset-password/${token}`, {
        newPassword: password,
      })
      .then((response) => {
        console.log("Sucesso!", response.data);
        navigate("/");
      })
      .catch((error) => console.log("ERROR", error));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form style={{ width: "400px" }}>
        <FormLabel style={{ fontWeight: 700 }}>Nova senha</FormLabel>
        <Input
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          placeholder="Digite sua nova senha"
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            style={{
              backgroundColor: "#00ACFF",
              color: "white",
              marginTop: "20px",
            }}
            onClick={() => {
              if (password.length > 1) {
                changePassword();
              }
            }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
