import React, { useContext, useState } from "react";
import { Button, Input, DatePicker, Tooltip, Row, Col, Typography } from "antd";
import "./AddMaquina.css";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import * as links from "../../../../utils/links";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import question_icon from "../../../../assets/images/question.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";

const AddMaquina = (props) => {
  const { authInfo, setDataUser, setNotiMessage } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { maquinaInfos, clienteInfo } = location.state;

  const token = authInfo?.dataUser?.token;

  const { id } = useParams();

  const [data, setData] = useState({
    nome: "",
    descricao: "",
    valorDoPix: "",
    valorDoPulso: "",
    store_id: "",
    maquininha_serial: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => {
      let errorsTemp = { ...prev };
      delete errorsTemp[name];
      return errorsTemp;
    });
  };

  const onSave = () => {
    // check require
    let errorsTemp = {};
    if (data.nome.trim() === "") {
      errorsTemp.nome = "Este campo é obrigatório";
    }
    if (data.descricao.trim() === "") {
      errorsTemp.descricao = "Este campo é obrigatório";
    }
    // if (data.valorDoPix.trim() === "") {
    //   errorsTemp.valorDoPix = "Este campo é obrigatório";
    // }
    if (data.valorDoPulso.trim() === "") {
      errorsTemp.valorDoPulso = "Este campo é obrigatório";
    }

    if (Object.keys(errorsTemp).length > 0) {
      setErrors(errorsTemp);
      return;
    }
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVIDOR}/maquina`,
        {
          nome: data.nome,
          descricao: data.descricao,
          valorDoPix: data.valorDoPix,
          clienteId: id,
          valorDoPulso: data.valorDoPulso,
          store_id: String(data.store_id),
          maquininha_serial: String(data.maquininha_serial),
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        navigate(`${links.CLIENTES_MAQUINAS}/${id}`, {
          state: location.state,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        setNotiMessage({
          type: "error",
          message: err.response?.data?.error
            ? err.response?.data?.error
            : `A sua sessão expirou, para continuar faça login novamente.`,
        });
      });
  };

  return (
    <>
      {isLoading && <LoadingAction />}
      <div className="AddMaquina_container" style={{ paddingRight: "15px" }}>
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <ArrowLeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography style={{ fontSize: "24px", fontWeight: "700" }}>
              Adicionar máquina
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                borderRadius: "8px",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#26A95F",
                color: "white",
                borderRadius: "8px",
                padding: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                if (!isLoading) onSave();
              }}
              disabled={isLoading}
            >
              Salvar
              <SaveOutlined />
            </Button>
          </div>
        </Row>
        <Col
          style={{
            width: "100%",
            padding: "20px",
            border: "1px solid #EBEBEF",
            borderRadius: "8px",
            marginTop: "20px",
          }}
        >
          <Row style={{ width: "100%", gap: "20px" }}>
            <Col style={{ width: "50%" }}>
              {" "}
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="nome"
                style={{ fontWeight: "700" }}
              >
                Nome da máquina
              </label>
              <Input
                placeholder={"Nome da máquina"}
                value={data.nome}
                id="nome"
                type="text"
                name="nome"
                autoComplete="nome"
                onChange={(event) => {
                  handleChange("nome", event.target.value);
                }}
                className={`${!!errors.nome ? "AddCliente_inputError" : ""}`}
                style={{ borderRadius: "8px" }}
              />
              {errors.nome && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.nome}
                </div>
              )}
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Descrição
              </label>

              <Input
                placeholder={"Nome da loja"}
                value={data.descricao}
                id="descricao"
                type="text"
                name="nome"
                autoComplete="descricao"
                onChange={(event) => {
                  handleChange("descricao", event.target.value);
                }}
                className={`${
                  !!errors.descricao ? "Update_Pagamento_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.descricao && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.descricao}
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ width: "50%" }}>
              {" "}
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="nome"
                style={{ fontWeight: "700" }}
              >
                Store ID
              </label>
              <Input
                placeholder={"Nome da máquina"}
                value={data.store_id}
                id="store_id"
                type="text"
                name="store_id"
                autoComplete="store_id"
                onChange={(event) => {
                  handleChange("store_id", event.target.value);
                }}
                className={`${
                  !!errors.store_id ? "AddCliente_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.store_id && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.store_id}
                </div>
              )}
            </Col>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Maquininha serial
              </label>

              <Input
                placeholder={"Nome da loja"}
                value={data.maquininha_serial}
                id="descricao"
                type="text"
                name="nome"
                autoComplete="maquininha_serial"
                onChange={(event) => {
                  handleChange("maquininha_serial", event.target.value);
                }}
                className={`${
                  !!errors.maquininha_serial
                    ? "Update_Pagamento_inputError"
                    : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.maquininha_serial && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.maquininha_serial}
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ width: "100%", gap: "20px", marginTop: "20px" }}>
            <Col style={{ flex: 1 }}>
              <label
                className="AddCliente_itemFieldLabel"
                htmlFor="mercadoPagoToken"
                style={{ fontWeight: "700" }}
              >
                Valor do pulso
              </label>

              <Input
                placeholder={"1.50"}
                value={data.valorDoPulso}
                id="valorDoPulso"
                type="number"
                name="valorDoPulso"
                autoComplete="valorDoPulso"
                onChange={(event) => {
                  handleChange("valorDoPulso", event.target.value);
                }}
                className={`${
                  !!errors.valorDoPulso ? "Update_Pagamento_inputError" : ""
                }`}
                style={{ borderRadius: "8px" }}
              />
              {errors.valorDoPulso && (
                <div className="Update_Pagamento_itemFieldError">
                  {errors.valorDoPulso}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default AddMaquina;
